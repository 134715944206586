<template>
  <div>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 class="font-weight-bold my-0 " v-text=" $t('components.dashboard.shipping.countries.title' ) ">
        </h5>
        <button class="btn p-0 btn-link ml-auto d-none " @click=" modalCountry = !modalCountry  ; fnResetCountry(); ">
          <i class=" fa fa-plus-circle fa-lg animated fadeIn "></i>
        </button>
      </div>
      <div class="card-body">

        <div v-show=" shippingCountries.length > 0 ">
          <!-- Mobile -->
          <div class=" d-lg-none">
            <table class=" table table-striped ">
              <thead>
                <tr>
                  <th v-text=" $t('components.dashboard.shipping.countries.countryName' ) "> </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (country,index ) in shippingCountries  " :key="index">

                  <td>

                    <div class="row align-items-center mb-2">
                      <div class="col-6 col-md-8 ">
                        <p class="  ">
                          <i class=" flag-icon mr-2" :class=" 'flag-icon-' + (country.code).toLowerCase() "></i>
                          <strong v-text=" country.name "></strong>
                        </p>
                      </div>

                      <div class="col-6 col-sm-auto ml-auto">
                        <div class="row  h-100">
                          <div class="col-auto ml-auto">
                            <button class=" btn btn-sm btn-success " @click="fnUpdateShippingCountry( index )">
                              <strong v-text=" $t('general.form.discount') ">
                              </strong>
                            </button>
                          </div>
                          <div class="col-auto d-none">
                            <button class=" btn btn-sm btn-outline-danger " @click="fnApiDeleteCountry( )">
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row align-items-center ">

                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <p>
                              <span v-text=" $t('components.dashboard.shipping.countries.shippingProvider' ) + ': ' ">
                              </span>
                              <strong class="text-uppercase" v-text=" country.type "
                                :class=" (country.type == 'own' )? 'text-danger': 'text-success' "></strong>
                            </p>
                          </div>
                          <div class="col-12 col-md-6">
                            <div v-if=" country.type == 'ecart' ">
                              <p class="m-0 ">
                                <span v-text=" $t('components.dashboard.shipping.countries.discount' ) +': ' ">
                                </span>
                                <strong
                                  :class=" country.discount == 100 ? 'text-success' : ( country.discount == 0 ?  'text-muted': '') ">
                                  <span
                                    v-text=" ( country.discount == 100 ?  $t('general.freeShipping' ) : ( country.discount == 0 ? $t('general.none' ) :  '%' + country.discount ) ) ">
                                  </span>
                                  <i class="fa fa-star ml-1" v-show=" country.discount == 100 "></i>
                                </strong>
                              </p>
                            </div>
                            <div v-else>
                              <div class="row">
                                <div class="col-6 col-md-6" v-for=" (rate,index) in  country.rates " :key=" index  ">
                                  <span
                                    v-text=" ( ( index == 0 ) ? 'Express' : ( ( index == 1 ) ? 'Standard' : '' ) ) + ':' ">
                                  </span>
                                  <strong :class="   ( rate.active ) ? 'text-success' : 'text-danger' "
                                    v-text=" ( rate.active ) ? 'Active' : 'Inactive' "></strong>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-12">
                            <p class=" ">
                              <i v-show=" country.discount < 100 " class="fa fa-info-circle mr-1"></i>
                              <small v-show=" country.discount == 0 "
                                v-text=" $t('components.dashboard.shipping.countries.messages[0]' ) "></small>
                              <small v-show=" country.discount > 0  && country.discount <= 50 "
                                v-text=" $t('components.dashboard.shipping.countries.messages[1]' )"></small>
                              <small v-show=" country.discount > 50  && country.discount < 100 "
                                v-text=" $t('components.dashboard.shipping.countries.messages[2]' ) "></small>
                              <small v-show=" country.discount == 100 " class=" text-success "> <i
                                  class="fa fa-check-circle mr-1"></i> <span
                                  v-text=" $t('components.dashboard.shipping.countries.messages[3]' ) "></span>
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Desktop -->
          <table class="table d-none d-lg-table table-striped " v-show=" shippingCountries.length > 0 ">
            <thead class=" text-center  ">
              <tr>
                <th class=" text-left  w-25" v-text=" $t('components.dashboard.shipping.countries.countryName' ) ">
                </th>
                <th class="" v-text=" $t('general.form.shippingProvider') "> </th>
                <th class=" text-left " v-text=" $t('components.dashboard.shipping.countries.shippingDetails' ) "> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (country,index ) in shippingCountries  " :key="index" class="text-center "
                :class="  { 'bg-light ' : ( country.id == shippingCountry.id  )  }  ">

                <td class=" text-left ">
                  <p>
                    <i class="flag-icon mr-2" :class=" 'flag-icon-' + (country.code).toLowerCase()   "></i>
                    <strong v-text=" country.name "></strong>
                  </p>
                </td>

                <td>
                  <div class="row h-100 align-content-center ">
                    <div class="col-12">
                      <p class="text-uppercase">
                        <small>
                          <strong class=" p-2 rounded " v-text=" country.type "
                            :class="  {  'text-success':country.type == 'ecart',  'text-primary':country.type == 'own' }  "></strong>
                        </small>
                      </p>
                    </div>
                  </div>
                </td>

                <td class=" text-left ">
                  <div class="row">
                    <div class="col-lg-7 col-xl-9 ">
                      <div v-if=" country.type == 'ecart' ">
                        <p class=" mb-0 ">
                          <span v-text=" $t('components.dashboard.shipping.countries.discount' ) + ': '  ">
                          </span>
                          <strong
                            :class=" country.discount == 100 ? 'text-success' : ( country.discount == 0 ?  'text-muted': ' ') ">
                            <span
                              v-text=" ( country.discount == 100 ? $t('general.freeShipping' )  : ( country.discount == 0 ? $t('general.none' ) :  '%' + country.discount ) ) "></span>
                            <i class="fa fa-star ml-1" v-show=" country.discount == 100 "></i>
                          </strong>

                        </p>
                        <p class=" ">
                          <i v-show=" country.discount < 100 " class="fa fa-info-circle mr-1"></i>
                          <small v-show=" country.discount == 0 "
                            v-text=" $t('components.dashboard.shipping.countries.messages[0]' ) "></small>
                          <small v-show=" country.discount > 0  && country.discount <= 50 "
                            v-text=" $t('components.dashboard.shipping.countries.messages[1]' )"></small>
                          <small v-show=" country.discount > 50  && country.discount < 100 "
                            v-text=" $t('components.dashboard.shipping.countries.messages[2]' ) "></small>
                          <small v-show=" country.discount == 100 " class=" text-success "> <i
                              class="fa fa-check-circle mr-1"></i> <span
                              v-text=" $t('components.dashboard.shipping.countries.messages[3]' ) "></span>
                          </small>
                        </p>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-6 col-md-6" v-for=" (rate,index) in  country.rates " :key=" index  ">
                            <p>
                              <span
                                v-text="  ( ( index == 0 ) ?  'Express' : ( ( index == 1 ) ? 'Standard' : ''   ) ) + ':' ">
                              </span>
                              <strong :class="  ( rate.active ) ? 'text-success' : 'text-danger'  "
                                v-text=" ( rate.active ) ? 'Active' : 'Inactive' "></strong>
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-lg-5 col-xl-3 ">
                      <div class="row text-truncate   h-100">
                        <div class="col">
                          <button class=" btn btn-sm btn-success " @click="fnUpdateShippingCountry( index )">
                            <strong v-text=" $t('general.form.discount')"> </strong>
                          </button>
                        </div>
                        <div class="col d-none ">
                          <button class=" btn btn-sm btn-outline-danger " @click="fnApiDeleteCountry( )">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

              </tr>

            </tbody>
          </table>
        </div>
        <no-data v-show=" shippingCountries.length == 0 " :isLoading=" isLoading "
          @btn-fn=" modalCountry = !modalCountry  ; fnResetCountry(); "></no-data>
      </div>
    </div>

    <b-modal :title=" (shippingCountry.id != null) ? 'Update shipping country' : 'Add shipping country' "
      content-class="card card-ecart form-rounded-inputs"  header-class="card-header p-3 " v-model="modalCountry" @hidden="fnResetCountry()"
      cancel-variant="danger" size="lg" hide-header-close>

      <!-- ADD COUNTRY -->
      <v-observer tag='form' ref="formCountry" class=" animated fadeIn " @submit.prevent="fnApiAddCountry()">
        <div class="row">

          <div class="col-12 " :class="  shippingCountry.type == 'own' ? 'col-md-6 ' : 'col-md-4'">
            <div class="form-group">
              <label for="" v-text="$t('general.form.country')"> </label>

              <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.country') ">
                <select class="form-control" :class=" { 'is-invalid':errors.length > 0 } "
                  v-model="shippingCountry.code" :disabled="shippingCountry.id != null ">
                  <option :value="country.code" v-for="  (country, index ) in availableCountries  " :key="index"
                    v-text="country.name">
                  </option>
                </select>

                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>

            </div>
          </div>
          <div class="col-12" :class="  shippingCountry.type == 'own' ? 'col-md-6 ' : 'col-md-4'">
            <div class="form-group">
              <label for="">
                <span v-text=" $t('general.form.shippingProvider') "></span>
                <i class=" fa fa-circle text-success ml-1 "></i>
              </label>
              <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.shippingProvider') ">

                <select class="form-control" :disabled=" shippingCountry.id != null  "
                  :class=" { 'is-invalid':errors.length > 0 } " v-model="shippingCountry.type ">
                  <option value="ecart">Ecart
                  </option>
                  <option value="own">Own</option>
                </select>

                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>

            </div>
          </div>
          <div class="col-12 col-md-4 " v-if="shippingCountry.type == 'ecart' ">
            <div class="form-group">
              <label for="" v-text="$t('general.form.discount')">
                <button class=" p-0 btn btn-link text-info " type="button" @click="$bvModal.show('modal-ecart-info')">
                  <i class="  fa fa-circle text-info  "></i>
                </button>
              </label>

              <v-validation rules="required|min_value:0|max_value:100" v-slot="{ errors }"
                :name="$t('general.form.discount')">
                <input type="text" v-model="shippingCountry.discount " :class=" { 'is-invalid':errors.length > 0 } "
                  class="form-control ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>

            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-3 d-none ">
            <div class="form-group">
              <label for="">
                &nbsp;
              </label>
              <input type="submit" v-if=" shippingCountry.id == null || shippingCountry.type != 'own' "
                :value=" shippingCountry.id != null? 'Update' : 'Save' " class="btn btn-primary form-control ">

            </div>
          </div>

        </div>
      </v-observer>

      <!-- INFORMATION -->
      <div class="my-2">
        <p class="m-0">
          <small> <i class="  fa fa-circle text-success  "></i> Shipping provider : It is who is responsible
            for shipping </small>
        </p>
        <p class="m-0" v-show="shippingCountry.type == 'ecart'">
          <small> <i class="  fa fa-circle text-danger  "></i> Ecart : Is our platform which helps
            companies to
            integrate with our shipping platform </small>
        </p>
        <p class="m-0" v-show="shippingCountry.type == 'ecart'">
          <small> <i class="  fa fa-circle text-info  "></i>
            <span> Shipping discount: If the discount is 40% and the shipping price is $100 , the seller pay
              $40 and the buyer $60 </span>
          </small>
        </p>
      </div>

      <!-- UPDATE RATES  -->

      <div class="row animated fadeIn  " v-show="shippingCountry.type == 'own' ">
        <div class="col-12 animated fadeIn " v-show=" shippingCountry.id != null  ">
          <hr class=" my-4 ">
          <h6 class="text-uppercase font-weight-bold text-primary " v-text=" 'shipping prices ' "></h6>
        </div>
        <div v-for=" (rate, index ) in shippingCountry.rates " class="col-12 animated fadeIn " :key="index">

          <v-observer ref="formRate" class=" animated fadeIn  " @submit.prevent="fnApiAddCountry( )" tag="form"
            v-show="  !shippingCountry.rates[0].is_free || index == 0 || !shippingCountry.rates[0].active  ">

            <div class="row justify-content-center">
              <div class="col-12 ">
                <div class="row">

                  <div class="col-9">
                    <div class="row justify-content-between ">
                      <div class="col-9 ">
                        <strong v-text=" rate.label   ">
                        </strong>
                      </div>
                    </div>
                  </div>

                  <div class="col-auto ml-auto text-right ">

                    <div class="media">
                      <label class="col-form-label mr-1" :for=" 'rateAcrive'+ index "> Active
                      </label>
                      <div class="media-body text-right switch-sm switch-outline">
                        <label class="switch">
                          <input type="checkbox" :id=" 'rateAcrive'+ index " v-model=" rate.active "><span
                            class="switch-state bg-info "></span>
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="row animated fadeIn" v-show=" rate.active ">
              <div class="col-8 animated fadeIn ">
                <p>
                  <small
                    v-text=" '*' + ( (index == 0 ) ? 'The package arrives 1 to 2 days.' : ( (index == 1 ) ? 'The package arrives 3 to 5 days.' : ' ' ) )   ">
                  </small>
                </p>
              </div>
              <div class="col-auto ml-auto text-right">
                <div class="media">
                  <label class="col-form-label mr-1" :for=" 'rateFree'+ index "> It`s free </label>
                  <div class="media-body text-right switch-sm switch-outline">
                    <label class="switch">
                      <input type="checkbox" :id=" 'rateFree'+ index " v-model=" rate.is_free "><span
                        class="switch-state state bg-info"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 animated fadeIn" v-show="  !rate.is_free " v-if=" !rate.is_free ">
                <div class="form-group">
                  <label for="" v-text=" ' First item ' ">
                  </label>
                  <v-validation rules="required|min_value:1" v-slot="{ errors }" :name=" 'Cost' ">
                    <input type="text" v-model="rate.cost" :class=" { 'is-invalid':errors.length > 0 } "
                      class="form-control ">
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </v-validation>
                </div>
              </div>

              <div class="col-6 col-sm-6 animated fadeIn" v-show="  !rate.is_free " v-if=" !rate.is_free ">
                <div class=" form-group">
                  <label for="" v-text="  'Extra item' ">
                  </label>
                  <v-validation rules="required|min_value:1" v-slot="{ errors }" :name=" 'Extra' ">
                    <input type="text" v-model="rate.extra " :class=" { 'is-invalid':errors.length > 0 } "
                      class="form-control ">
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </v-validation>
                </div>

              </div>
            </div>

          </v-observer>

        </div>

      </div>

      <template v-slot:modal-footer>
        <div class="d-flex w-100 ">

          <div class="d-none">
            <button class=" btn btn-sm btn-danger " @click="fnApiDeleteCountry( )">
              <strong class="text-uppercase">
                <i class="fa fa-trash"></i>
              </strong>
            </button>
          </div>

          <div class="ml-auto">
            <div class="d-flex jusify-content-end ">
              <b-button variant="none" size="sm" class="mr-2 text-uppercase f-w-900 "
                @click=" modalCountry = !modalCountry ; fnResetCountry() " v-text=" 'Close' ">
              </b-button>

              <b-button variant="success" size="sm" class=" text-uppercase f-w-900" @click=" fnApiAddCountry() "
                :disabled="sendingDataShippingCountry">
                <span
                  v-text=" (shippingCountry.id == null &&  shippingCountry.type == 'own' ) ? 'Next' : (shippingCountry.id != null? 'Update' : 'Save' )"
                  v-if=" !sendingDataShippingCountry "></span>
                <i class="fa fa-spinner fa-spin " v-else></i>
              </b-button>
            </div>
          </div>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      countriesArray: {
        type: Array,
        default: [],
        required: true,
      },
      shippingCountriesArray: {
        type: Array,
        default: [],
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: true,
        required: true,
      }
    },
    data() {
      return ({

        availableCountries: [],
        shippingCountries: [],
        shippingCountry: {
          type: null,
        },
        modalCountry: false,
        sendingDataShippingCountry: false,
        tempIndex: null,
      })
    },
    watch: {
      countriesArray() {
        if (this.countriesArray.length > 0) {
          this.availableCountries = this.$lodash.clone(this.countriesArray);
        }
      },
      shippingCountriesArray() {

        this.shippingCountries = this.$lodash.clone(this.shippingCountriesArray);
        if (this.shippingCountries.length > 0) {
          for (let index in this.shippingCountries) {
            this.availableCountries = this.fnDeleteCountry(this.availableCountries, this.shippingCountries[
              index].code)
          }
        }
      },
      shippingCountries() {
        this.$emit('update-countries', 'countries', this.shippingCountries.length)
      }
    },
    methods: {
      fnGetShipping() {
        axios.get('me/settings/shipping').then(response => {
          this.shippingCountries = this.$lodash.clone(response.data.countries);
          for (let index in this.shippingCountries) {
            this.availableCountries = this.fnDeleteCountry(this.availableCountries, this
              .shippingCountries[index].code)
          }
          this.isLoading = false;

        }).catch(err => {});
      },

      async fnApiAddCountry() {
        if (await this.$refs['formCountry'].validate()) {
          let saved = false;
          // Update
          this.sendingDataShippingCountry = true;
          if (this.shippingCountry.id != null) {
            //OWN
            let id = this.shippingCountry.id;
            if (this.shippingCountry.type == 'own') {
              if (await this.fnApiUpdateCountryRates()) {
                this.modalCountry = false;
                saved = true;
              }
            } else {
              let {
                discount,
                type,
                zone_id
              } = this.shippingCountry;
              await axios.put('me/settings/shipping/countries/' + id, {
                discount,
                type,
                zone_id
              }).then((response) => {
                saved = true;
                let index = this.$lodash.findIndex(this.shippingCountries, ['id', this
                  .shippingCountry.id
                ]);
                this.shippingCountries.splice(index, 1);
                this.shippingCountries.unshift(response.data);
                this.modalCountry = false;
                this.availableCountries = this.fnDeleteCountry(this.availableCountries, this
                  .shippingCountry.code)
                this.fnResetCountry();

              }).catch(error => {});
            }
            if (saved) {
              this.$toasted.global.infoMessage(this.$t('toasted.info.shippingCountryUpdated'));
            }

          } else {

            //ADD NEW
            let index = this.$lodash.findIndex(this.availableCountries, ['code', this.shippingCountry
              .code
            ]);
            this.shippingCountry.name = this.availableCountries[index].name;
            await axios.post('me/settings/shipping/countries', this.shippingCountry).then((
              response) => {
              this.$toasted.global.infoMessage(this.$t('toasted.info.shippingCountryCreate'));
              this.shippingCountry = this.$lodash.cloneDeep(response.data);
              this.shippingCountries.unshift(response.data);
              if (this.shippingCountry.type != 'own') {
                this.modalCountry = false;
                this.availableCountries = this.fnDeleteCountry(this.availableCountries, this
                  .shippingCountry.code)
                this.fnResetCountry();
              }
            }).catch(error => {});
          }

          this.sendingDataShippingCountry = false;

        }
      },

      fnApiDeleteCountry() {

        let tempCountry = this.shippingCountries[this.tempIndex];
        this.shippingCountries.splice(this.tempIndex, 1)
        axios.delete('me/settings/shipping/countries/' + tempCountry.id).then(response => {
          this.availableCountries.push({
            code: tempCountry.code,
            name: tempCountry.name
          })

          this.availableCountries = this.fnSortCountries(this.availableCountries)
          this.fnResetCountry();
          this.modalCountry = false;
        }).catch(error => {});

      },

      fnUpdateShippingCountry(index) {
        this.tempIndex = index;
        if (this.shippingCountry.id) {
          let idx = this.$lodash.findIndex(this.availableCountries, ['code', this.shippingCountries[index]
            .code
          ]);
          this.availableCountries.splice(idx, 1);
        }

        this.shippingCountry = this.$lodash.cloneDeep(this.shippingCountries[index]);
        this.availableCountries.push({
          code: this.shippingCountry.code,
          name: this.shippingCountry.name
        });
        this.modalCountry = true;
      },

      async fnApiUpdateCountryRates() {
        // index
        let saved = false;

        for (let index in this.shippingCountry.rates) {
          if (await this.$refs['formRate'][index].validate()) {

            let tempRate = this.$lodash.clone(this.shippingCountry.rates[index]);
            if (tempRate.cost == null) {
              tempRate.cost = 0
            }
            if (tempRate.extra == null) {
              tempRate.extra = 0
            }
            delete tempRate.id;
            delete tempRate.label;
            let idx = this.$lodash.findIndex(this.shippingCountries, ['id', this.shippingCountry.id]);
            saved = await axios.put(
              `me/settings/shipping/countries/${this.shippingCountry.id}/rates/${this.shippingCountry.rates[index].id }`,
              tempRate).then(
              response => {
                this.shippingCountries[idx].rates[index] = response.data;
                let tempRateCountry = this.$lodash.cloneDeep(this.shippingCountries[idx]);
                this.shippingCountries.splice(idx, 1);
                this.shippingCountries.unshift(tempRateCountry);
                return true;
              }).catch(error => {});
            if (index == 0 && this.shippingCountry.rates[index].active && this.shippingCountry.rates[
                index].is_free) {
              break;
            }
          } else {
            return false
          }
        }
        return saved;
      },



      fnResetCountry() {
        this.tempIndex = null;
        if (this.shippingCountry.id) {
          let idx = this.$lodash.findIndex(this.availableCountries, ['code', this.shippingCountry.code]);
          this.availableCountries.splice(idx, 1);
        }

        if (this.availableCountries.length > 0) {
          this.shippingCountry = {
            code: this.availableCountries[0].code,
            type: 'ecart',
          };
        } else {
          this.shippingCountry = {
            type: 'ecart',
          };
        }
        this.availableCountries = this.fnSortCountries(this.availableCountries)
      },
    },

  }
</script>

<style>

</style>